type Title = {
  title: string;
};

type MetaProperty = {
  property: string;
  content: string;
};

type MetaName = {
  name: string;
  content: string;
};

type Meta = Title | MetaProperty | MetaName;

export const generateMetaTags = ({
  metaTitle = "BLAST.tv - Gaming Stats, Tournaments & News",
  metaDescription = "Discover all things CS, Dota, schedules, games and more. Transform your viewing experience, stay updated, and earn rewards through our mini-games daily.",
  metaImageUrl = "https://assets.blast.tv/social/BLAST.png",
  seoNoIndex = false,
}: {
  metaTitle?: string;
  metaDescription?: string;
  metaImageUrl?: string;
  seoNoIndex?: boolean;
}) => {
  const tags: Meta[] = [];

  tags.push({ title: metaTitle });

  tags.push({ name: "twitter:title", content: metaTitle });

  tags.push({ name: "og:site_name", content: "BLAST.tv" });
  tags.push({ name: "og:title", content: metaTitle });
  tags.push({ name: "og:url", content: "https://blast.tv" });

  // Twitter meta tags used to display the website preview when shared on Twitter
  tags.push({ name: "twitter:card", content: "summary_large_image" });
  tags.push({ name: "twitter:site", content: "@BLASTtv" });
  tags.push({ name: "twitter:creator", content: "@BLASTtv" });

  if (metaDescription) {
    tags.push({ name: "description", content: metaDescription });
    tags.push({ name: "twitter:description", content: metaDescription });
    tags.push({ name: "og:description", content: metaDescription });
  }

  if (metaImageUrl) {
    tags.push({ name: "twitter:image", content: metaImageUrl });
    tags.push({ name: "og:image", content: metaImageUrl });
  }

  if (seoNoIndex) {
    tags.push({ name: "robots", content: "noindex" });
  }

  return tags;
};
